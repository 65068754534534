/* eslint-disable react-hooks/exhaustive-deps */
import { Form, Input, Row, Col, Card } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { useHistory } from "react-router-dom";
import { axiosInstance, BASE_API_CONFIG } from "util/Api";
import Upload from "../../components/Upload";
import { EnquiryStatus } from "util/Constant";
import { labelComponent } from "util/UtilComponents";
import { Badge } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Select } from "antd";
import { Option } from "antd/es/mentions";
import { LeftOutlined } from "@ant-design/icons";
import { getLabel, isValidElement, isValidString } from "../../util/ApiHelper";
import { InputNumber } from "antd";
import { isExporter, isManufacturer } from "../../util/helper";
import { safeStringValue } from "../../util/ApiHelper";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";
import { isFreightForwarder } from "../../util/helper";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { BUSINESS_TYPE } from "../../util/Constant";

const { TextArea } = Input;

const Quotation = ({ enquriesData, quotationData }) => {
  const [form] = useForm();
  const history = useHistory();
  const { isMobile } = useGetBreakpoints();
  const [fileList, setFileList] = useState([
    {
      src: "",
    },
  ]);

  const countries = useSelector(({ common }) => common.countries);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const role = queryParams.get("role") || "general";
  
  const authUser = useSelector(({ auth }) => auth.userData);

  const validateTextArea = (_, value) => {
    if (value && value.length > 1000) {
      return Promise.reject(
        new Error("Product Requirements cannot exceed 1000 characters")
      );
    }
    return Promise.resolve();
  };

  const onUpload = (data) => {
    setFileList((prevFileList) => {
      const updatedList = [...prevFileList];
      updatedList[data.index].src = data.src;
      if (updatedList.length < 4) {
        updatedList.push({ src: "" });
      }
      updatedList[data.index].originalName = data?.originalName;
      return [...updatedList];
    });
  };
  const onRemove = (data) => {
    setFileList((prevFileList) => {
      const updatedList = [...prevFileList];
      updatedList[data.index].src = "";
      return updatedList;
    });
  };

  useEffect(() => {
    if (enquriesData) {
      form.setFieldsValue({
        unit: enquriesData?.units,
        currency: isManufacturer(authUser) ? "INR" : null,
      });
    }
  }, [enquriesData]);

  const currency = Form.useWatch("currency", form);

  useEffect(() => {
    if (quotationData) {
      form.setFieldsValue({
        price: quotationData?.data?.data?.price,
        unit: quotationData?.data?.data?.units,
        responseMessage: quotationData?.data?.data?.description,
        currency: quotationData?.data?.data?.currency,
        paymentTerms: quotationData?.data?.data?.paymentTerms,
        locationForDelivery: quotationData?.data?.data?.locationForDelivery,
      });
      const attachments = quotationData?.data?.data?.attachments;
      if (attachments) {
        if (attachments?.length < 4) {
          const images = attachments;
          images.push({ src: "" });
          setFileList([...images]);
        } else {
          setFileList([...attachments]);
        }
      }
    } else {
      if (!isValidElement(currency)) {
        if (isManufacturer(authUser))
          form.setFieldsValue({ currency: "INR", unit: enquriesData?.units });
      }
    }
  }, [quotationData]);

  const onSubmit = async (data) => {
    const response = await axiosInstance.post(
      `${BASE_API_CONFIG.baseURL}/quotations`,
      data
    );
    if (response.status === 200) {
      history.goBack();
    }
  };
  const onFinish = (values) => {
    let data = {
      price: values?.price,
      units: values?.unit,
      enquiryId: enquriesData?.id,
      senderId: authUser?.id,
      receiverId: enquriesData?.user?.id,
      netAmount: enquriesData?.qty * values?.price,
      status: EnquiryStatus.QUOTATION_SENT,
      description: values?.responseMessage,
      attachments: [...fileList.filter((file) => file.src)],
      currencyCode: values?.currency,
      expiryDate: values.expiryDate,
      paymentTerms: values.paymentTerms,
      locationForDelivery: values.locationForDelivery,
    };
    if (!isValidElement(values?.currency)) data["currencyCode"] = "INR";
    if (!isValidElement(values?.unit)) data["units"] = enquriesData?.units;

    onSubmit(data);
  };

  const isOrderPlaced = () => {
    return quotationData?.data?.data?.status === EnquiryStatus.ORDER_PLACED;
  };

  const uploadComponent = (isDisabled) => {
    return (
      <div className="attachments">
        <div className="sub-header"> Attachments</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {fileList.map((file, index) => {
            return (
              <Upload
                isDisabled={isDisabled}
                index={index}
                src={file.src}
                id={index}
                label={
                  isValidString(file?.label) ? file?.label : getLabel(index)
                }
                originalName={file.originalName}
                onUpload={onUpload}
                onRemove={onRemove}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const paymentTerms = Form.useWatch("paymentTerms", form);
  const locationForDelivery = Form.useWatch("locationForDelivery", form);

  return (
    <Card className="myexim-detail-card">
      <div className="myexim-table-title">
        <LeftOutlined
          className={"back-icon"}
          size="large"
          onClick={() => {
            history.goBack();
          }}
        />
        Quotation Details
      </div>
      <Form form={form} onFinish={onFinish} className="gx-mt-4">
        <Row gutter={16}>
          {enquriesData?.targetedBusinessType != "custom_house_agent" &&
            enquriesData?.targetedBusinessType != "shipping_line" && (
              <>
                <Row gutter={16} span={24} style={{ marginLeft: 3 }}>
                  {!(
                    isManufacturer(authUser) || isFreightForwarder(authUser) || role === BUSINESS_TYPE.EXPORTER
                  ) &&  (
                    <Col span={8} xs={24} sm={12}>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label={"Delivery Terms"}
                        name="paymentTerms"
                        rules={[
                          {
                            required: true,
                            message: "Delivery Terms is required",
                          },
                        ]}
                      >
                        <Select placeholder="Select Delivery Term">
                          {isManufacturer(authUser) ? (
                            <>
                              <Option value={"EXW"}>EXW - Ex Works</Option>
                              <Option value={"EXM"}>EXM - Ex Mill</Option>
                              <Option value={"FOR"}>
                                FOR - Freight on Road
                              </Option>
                            </>
                          ) : isExporter(authUser) ? (
                            <>
                              <Option value={"FOB"}>FOB - Free On Board</Option>
                              <Option value={"CFR"}>
                                CFR - Cost and Freight
                              </Option>
                              <Option value={"CIF"}>
                                CIF - Cost Insurance and Freight
                              </Option>
                              <Option value={"DAP"}>
                                DAP - Delivered at Place
                              </Option>
                              <Option value={"FAS"}>
                                FAS - Free Alongside Ship
                              </Option>
                            </>
                          ) : (
                            <>
                              <Option value={"FOB"}>FOB - Free On Board</Option>
                              <Option value={"CFR"}>
                                CFR - Cost and Freight
                              </Option>
                              <Option value={"EXM"}>EXM - Ex Mill</Option>
                              <Option value={"FOR"}>
                                FOR - Freight on Road
                              </Option>
                              <Option value={"CIF"}>
                                CIF - Cost Insurance and Freight
                              </Option>
                              <Option value={"EXW"}>EXW - Ex Works</Option>

                              <Option value={"DAP"}>
                                DAP - Delivered at Place
                              </Option>
                              <Option value={"FAS"}>
                                FAS - Free Alongside Ship
                              </Option>
                            </>
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}

                  {paymentTerms === "FOR" && (
                    <Col span={8} xs={24} sm={12}>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label={labelComponent("Location for delivery")}
                        name="locationForDelivery"
                        rules={[
                          {
                            required: true,
                            message: "Location for delivery is required",
                          },
                        ]}
                      >
                        <Input placeholder="Location for delivery" />
                      </Form.Item>
                    </Col>
                  )}

                  {!isManufacturer(authUser) && role !== BUSINESS_TYPE.EXPORTER &&(
                    <Col span={8} xs={24} sm={12}>
                      <Form.Item
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        label={labelComponent(
                          "Currency",
                          "Select the Quotation Currency"
                        )}
                        name="currency"
                        rules={[
                          {
                            required: true,
                            message: "Currency is required",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          size="large"
                          placeholder="Select Currency"
                          disabled={isManufacturer(authUser)}
                        >
                          {countries
                            ?.filter((country, index) => {
                              if (isManufacturer(authUser)) {
                                return country.currencyCode === "INR";
                              } else {
                                return (
                                  index ===
                                  countries.findIndex((o) => {
                                    return (
                                      country.currencyCode === o.currencyCode
                                    );
                                  })
                                );
                              }
                            })
                            .map(
                              (option) =>
                                option.currencyCode && (
                                  <Option
                                    key={option.id}
                                    value={option.currencyCode}
                                  >
                                    {option.currencyCode}
                                  </Option>
                                )
                            )}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={8} xs={24} sm={12}>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      label={labelComponent(
                        `Price / ${safeStringValue(
                          enquriesData?.units,
                          enquriesData?.shipmentMode === "sea"
                            ? enquriesData?.shipmentType === "fcl"
                              ? `Unit (${enquriesData?.containerType})`
                              : "Cubic Meter"
                            : "Cubic Meter"
                        )}`,
                        "Unit of Measurement displayed here is based on the Submitted Enquiry."
                      )}
                      name="price"
                      rules={[
                        {
                          required: true,
                          message: "Price is required",
                        },
                      ]}
                    >
                      <InputNumber
                        min={1}
                        placeholder="Enter Price"
                        style={{ width: "100%" }}
                        addonBefore={
                          !!currency
                            ? currency
                            : isManufacturer(authUser)||role === BUSINESS_TYPE.EXPORTER
                            ? "INR"
                            : null
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8} xs={24} sm={12}>
                    <Form.Item
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      label={labelComponent("Quotation Expiry date")}
                      name="expiryDate"
                      rules={[
                        {
                          required: true,
                          message: "Expiry date is required",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Expiry date"
                        size={"large"}
                        type={"date"}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

          <Col span={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label={labelComponent("Response Message")}
              name="responseMessage"
              rules={[
                {
                  validator: validateTextArea,
                },
                {
                  required: true,
                  message: "Response Message is required",
                },
              ]}
            >
              <TextArea
                showCount
                maxLength={200}
                rows={10}
                placeholder={
                  enquriesData?.targetedBusinessType == "custom_house_agent" ||
                  enquriesData?.targetedBusinessType == "shipping_lines"
                    ? "Enter your Response Message and attach your Quotation details"
                    : "Enter Payment terms, Product Specifications and any other details required for Buyer"
                }
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            {uploadComponent()}
          </Col>
          <Col sm={24} md={12}>
            <div className="upload-tips gx-mt-4 gx-mb-4">
              <div className="header" style={{ display: "flex" }}>
                <InfoCircleOutlined />
                &nbsp; Tips{" "}
              </div>
              <div className="desc">
                <Badge
                  status="default"
                  text="Attach the Image / Video for reference"
                />
                <br />
                <Badge
                  status="default"
                  text="Supported file types: .jpg, .jpeg, .png .pdf"
                />
                <br />
                <Badge status="default" text="File must not more than 5MB" />
                <br />
                <Badge
                  status="default"
                  text="Make sure you have good resolution"
                />
                <br />
                <Badge
                  status="default"
                  text="Photo resolution must be more than 600x600 resolution"
                />
                <br />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              className="gx-text-center gx-mr-3"
              style={{
                justifyContent: isMobile ? "center" : "end",
                display: "flex",
                flex: 1,
              }}
            >
              <Button
                type="secondary"
                size="large"
                className={"submit-btn"}
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className={"submit-btn"}
              >
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default Quotation;
