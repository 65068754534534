export const RFQStatus = {
  DRAFT: "draft",
  PENDING_APPROVAL: "pending_approval",
  PUBLISHED: "published",
  QUOTATION_RECEIVED: "quotation_received",
  ORDER_PLACED: "order_placed",
  ORDER_CONFIRMED: "order_confirmed",
  QUOTATION_SENT: "quotation_sent",
  REJECTED: "rejected",
  APPROVED: "approved",
};

export const EnquiryStatus = {
  QUOTATION_SENT: "quotation_sent",
  QUOTATION_RECEIVED: "quotation_received",
  ORDER_PLACED: "order_placed",
  ORDER_CONFIRMED: "order_confirmed",
  NEW_ENQUIRY: "new_enquiry",
  AWAITING_FOR_QUOTATION: "awaiting_for_quotation",
  PUBLISHED: "published",
  REJECTED: "rejected",
  DRAFT: "draft",
  APPROVED: "approved",
  PENDING_APPROVAL: "pending_approval",
};

export const BUSINESS_TYPE = {
  EXPORTER: "exporter",
  BUYER: "buyer",
  MANUFACTURER: "manufacturer",
  COMMISSION_AGENT: "commission_agent",
  CUSTOM_HOUSE_AGENT: "custom_house_agent",
  FREIGHT_FORWARDER: "freight_forwarder",
  SHIPPING_LINE: "shipping_line",
  MANUFACTURER_EXPORTER:"manufacturer_exporter"
};

export const BUSINESS_TYPE_FOR_MESSAGE = {
  exporter: "Exporter",
  buyer: "Buyer",
  manufacturer: "Manufacturer",
  commission_agent: "Commission Agent",
  custom_house_agent: "Custom House Agent (CHA)",
  freight_forwarder: "Freight Forwarder",
  shipping_line: "Shipping Line",
  manufacturer_exporter:"Manufacturer Cum Exporter"
};
export const BUSINESS_TYPE_LABEL = {
  exporter: "Exporter",
  buyer: "Buyer",
  manufacturer: "Manufacturer",
  commission_agent: "Commission Agent",
  custom_house_agent: "Custom House Agent",
  freight_forwarder: "Freight Forwarder",
  shipping_line: "Shipping Line",
  manufacturer_exporter:"Manufacturer Cum Exporter"
};
export const BUSINESS_TYPE_ID = {
  1: "buyer",
  2: "exporter",
  3: "manufacturer",
  4: "commission_agent",
  5: "custom_house_agent",
  6: "freight_forwarder",
  7: "shipping_line",
  8: "manufacturer_exporter"
};

export const APP_TYPE = {
  ADMIN: "admin",
  USER: "user",
};

export const BUSINESS_CARD_STATUS = {
  SENT: "sent",
  ACCEPTED: "accepted",
  REJECTED: "rejected",
};

export const PRODUCT_STATUS = {
  DRAFT: "draft",
  PENDING_APPROVAL: "pending_approval",
  PUBLISHED: "published",
  REJECTED: "rejected",
};

export const USER_STATUS = {
  PENDING_APPROVAL: "pending_approval",
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const total_quotations = 100;
export const business_card_request = true;

export const CERT_KEY = {
  GST: "GST",
  EXIM: "EXIM",
  COMPANY: "CC",
};
export const LEAD_TITLE ={
  LEADS:"Leads",
  BUYER_LEADS:"Buyer Leads",
  EXPORTER_LEADS:"Exporter Leads",
}
