import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import "../banner/banner.less";
import { Grid } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import promoimg from "../../../assets/images/promo.svg";
import lineDesign from "../../../assets/images/line-design.svg";
import {
  isBuyer,
  isCHA,
  isCommissionAgent,
  isExporter,
  isFreightForwarder,
  isManufacturer,
  isManufacturerCumExporter,
  isShippingLine,
} from "../../../util/helper";
import { Promotions } from "./PromotionData";
import { BUSINESS_TYPE } from "../../../util/Constant";

const StaticBanner = () => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isMobile = !screens.md;
  const authUser = useSelector(({ auth }) => auth.userData);

  const getUserType = (authUser) => {
    if (isExporter(authUser)) return BUSINESS_TYPE.EXPORTER;
    if (isBuyer(authUser)) return BUSINESS_TYPE.BUYER;
    if (isManufacturerCumExporter(authUser))
      return BUSINESS_TYPE.MANUFACTURER_EXPORTER;
    if (isManufacturer(authUser)) return BUSINESS_TYPE.MANUFACTURER;
    if (isCommissionAgent(authUser)) return BUSINESS_TYPE.COMMISSION_AGENT;
    if (isFreightForwarder(authUser)) return BUSINESS_TYPE.FREIGHT_FORWARDER;
    if (isShippingLine(authUser)) return BUSINESS_TYPE.SHIPPING_LINE;
    if (isCHA(authUser)) return BUSINESS_TYPE.CUSTOM_HOUSE_AGENT;
  };
  const userType = getUserType(authUser);

  let userPromotions;

  switch (userType) {
    case BUSINESS_TYPE.EXPORTER:
      userPromotions = Promotions.PROMOTIONS_EXPORTER;
      break;
    case BUSINESS_TYPE.BUYER:
      userPromotions = Promotions.PROMOTIONS_BUYER;
      break;
    case BUSINESS_TYPE.MANUFACTURER_EXPORTER:
      userPromotions = Promotions.PROMOTIONS_MANUFACTURER_EXPORTERS;
      break;
    case BUSINESS_TYPE.MANUFACTURER:
      userPromotions = Promotions.PROMOTIONS_MANUFACTURER;
      break;
    case BUSINESS_TYPE.COMMISSION_AGENT:
      userPromotions = Promotions.PROMOTIONS_COMMISSION_AGENTS;
      break;
    case BUSINESS_TYPE.FREIGHT_FORWARDER:
      userPromotions=Promotions.PROMOTIONS_FREIGHT_FORWARDERS;
      break;
    case BUSINESS_TYPE.SHIPPING_LINE:
      userPromotions=Promotions.PROMOTIONS_SHIPPING_LINES;
      break;
    case BUSINESS_TYPE.CUSTOM_HOUSE_AGENT:
      userPromotions = Promotions.PROMOTIONS_CUSTOM_HOUSE_AGENT;
      break;  
  }

  const PrevArrow = ({ onClick }) => (
    <div className="custom-arrow custom-prev-arrow" onClick={onClick}>
      <LeftOutlined style={{ color: "#FFFFFF" }} />
    </div>
  );

  const NextArrow = ({ onClick }) => (
    <div className="custom-arrow custom-next-arrow" onClick={onClick}>
      <RightOutlined style={{ color: "#FFFFFF" }} />
    </div>
  );

  const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return userPromotions.length > 0 ? (
    <div>
      <Slider {...settings}>
        {userPromotions.map((promotion) => (
          <div
            key={promotion.id}
            className={
              isMobile ? "mobile-promotion-banner" : "promotion-banner"
            }
          >
            <div className={isMobile ? "mobile-banner-image" : "banner-image"}>
              <img src={promoimg} alt="promo-img" />
              <img
                src={lineDesign}
                alt="watermark"
                className="watermark"
                style={{
                  opacity: 0.1,
                  position: "absolute",
                  top: "10%",
                  left: "10%",
                  transform: "translate(-50%, -50%)",
                  zIndex: -1,
                  width: "40%",
                  height: "300%",
                }}
              />
            </div>
            <div>
              <h2 className={isMobile ? "mobile-banner-title" : "banner-title"}>
                {promotion.title}
              </h2>
              <p className={isMobile ? "mobile-banner-desc" : "banner-desc"}>
                {promotion.description}
              </p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  ) : null;
};

export default StaticBanner;
