import { Form, Input, Row, Col, Select, Card, message } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "antd";
import {
  createProductAction,
  getCategoryListAction,
  getHSCodeAction,
  getProductsById,
} from "appRedux/actions/myProductsAction";
import { useDispatch, useSelector } from "react-redux";
import Upload from "../../components/Upload";
import { useHistory } from "react-router-dom";
import { isValidElement, isValidString, getLabel } from "util/ApiHelper";
import { useForm } from "antd/es/form/Form";
import { showAlert } from "util/helper";
import { PRODUCT_STATUS } from "util/Constant";
import { InfoCircleOutlined, LeftOutlined } from "@ant-design/icons";
import { Badge } from "antd";
import { useGetBreakpoints } from "../../hooks/useGetBreakpoints";

const { TextArea } = Input;
const FormItem = Form.Item;

const ProductDetails = () => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const history = useHistory();
  const { isMobile } = useGetBreakpoints();
  const [hsCodeData, setHsCodeData] = useState(null);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");
  let isEditDisabled = false;

  useEffect(() => {
    dispatch(getCategoryListAction());
    dispatch(getHSCodeAction());
    if (isValidString(id)) {
      dispatch(getProductsById(id));
    }
  }, [dispatch, id]);

  const productDetails = useSelector(
    (state) => state.myProducts.productsDetailsById
  );
  const authUser = useSelector(({ auth }) => auth.userData);
  const categoryList = useSelector((state) => state.myProducts.categoryList);
  const hsCodeList = useSelector((state) => state.myProducts.hsCodeList);

  const [filteredHsCodeData, setFilteredHsCodeData] = useState(hsCodeList);

  const [dataId, setDataId] = useState();
  const [fileList, setFileList] = useState([
    {
      label: "primary-image",
    },
  ]);

  useEffect(() => {
    if (isValidElement(productDetails) && isValidString(id)) {
      form.setFieldsValue({
        productName: productDetails?.name,
        productCategory: productDetails?.categoryId,
        hscode: productDetails?.hsCodeId,
        productdescription: productDetails?.description,
        productSpecification: productDetails?.specification,
      });
      setDataId(productDetails?.id);
      if (productDetails?.images.length < 4) {
        const images = productDetails?.images;
        images.push({ src: "" });
        setFileList([...images]);
      } else {
        setFileList([...productDetails?.images]);
      }
    }
  }, [form, id, productDetails]);

  const onFinish = (values) => {
    const data = {
      name: values?.productName,
      description: values?.productdescription,
      specification: values?.productSpecification,
      isActive: true,
      primaryImage: isValidString(fileList[0].src) ? fileList[0]?.src : "",
      userId: authUser?.id,
      categoryId: values?.productCategory,
      hsCodeId: values?.hscode,
      images: [...fileList.filter((file) => file.src)],
      status: PRODUCT_STATUS.PENDING_APPROVAL,
      remarks: "",
    };
    if (!data.primaryImage) {
      showAlert("error", "Please upload primary image");
      return;
    }
    dispatch(createProductAction(data, dataId));
  };

  const onUpload = (data) => {
    setFileList((prevFileList) => {
      const updatedList = [...prevFileList];
      updatedList[data.index].src = data.src;
      if (updatedList.length < 4) {
        updatedList.push({ src: "" });
      }
      updatedList[data.index].originalName = data.originalName;
      return [...updatedList];
    });
  };

  const onRemove = (data) => {
    setFileList((prevFileList) => {
      const updatedList = [...prevFileList];
      updatedList[data.index].src = "";
      return updatedList;
    });
  };

  const { Option } = Select;
  const handleChangeProductCategory = (value) => {
    form.resetFields(["hsCode"]);
    setFilteredHsCodeData(null);
    setHsCodeData(hsCodeList?.filter((item) => item?.categories?.id === value));
  };

  const handleHSCodeSearch = (value) => {
    const trimmedValue = value.trim();
    const containsNumbers = /^\d+$/.test(trimmedValue.toLowerCase());

    setFilteredHsCodeData(() => {
      const filterFunction = (option) => {
        const codeString = option.code
          .toString()
          .replace(/\s+/g, "")
          .toLowerCase();
        const descriptionString = option.description
          .replace(/[\n\r"]/g, "")
          .toLowerCase();

        return containsNumbers
          ? codeString.includes(trimmedValue.toLowerCase())
          : descriptionString.includes(trimmedValue.toLowerCase());
      };

      return isValidElement(hsCodeData)
        ? hsCodeData.filter(filterFunction)
        : hsCodeList?.filter(filterFunction);
    });
  };

  return (
    <Card
      className="myexim-detail-card"
      title={
        <div className="myexim-table-title gx-text-capitalize">
          <LeftOutlined
            className={"back-icon"}
            size="large"
            onClick={() => {
              history.goBack();
            }}
          />
          {dataId ? "Edit" : "Add"} Product
        </div>
      }
    >
      <Form form={form} onFinish={onFinish} scrollToFirstError={true}>
        <Row gutter={16}>
          <Col span={8} xs={24} sm={12} md={8}>
            <FormItem
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Product Category"
              name="productCategory"
              rules={[
                {
                  required: true,
                  message: "Product Category is required",
                },
              ]}
            >
              <Select
                showSearch
                size="large"
                name="productCategory"
                placeholder="Choose Product Category"
                dropdownStyle={{ maxHeight: "200px" }}
                onChange={handleChangeProductCategory}
                getPopupContainer={(trigger) => trigger.parentNode}
                listItemHeight={10}
                listHeight={250}
                filterOption={(input, option) => {
                  const children = option?.children ?? "";
                  const optionText = children.props?.children ?? "";
                  return optionText.toLowerCase().includes(input.toLowerCase());
                }}
              >
                {categoryList?.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    <div style={{ whiteSpace: "normal" }}>
                      {option.name.charAt(0).toUpperCase() +
                        option.name.slice(1).toLowerCase()}
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={8} xs={24} sm={12} md={8}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="HS code"
              name="hscode"
              rules={[
                {
                  required: true,
                  message: "HS code is required",
                },
              ]}
            >
              <Select
                showSearch
                size="large"
                name="hscode"
                placeholder="Choose HS code / Item Description"
                filterOption={false}
                disabled={isEditDisabled}
                getPopupContainer={(trigger) => trigger.parentNode}
                onSearch={handleHSCodeSearch}
              >
                {isValidElement(filteredHsCodeData)
                  ? filteredHsCodeData?.map((option) => (
                      <Option key={option.id} value={option.id}>
                        <div style={{ whiteSpace: "normal" }}>
                          {option.code} -{" "}
                          {option.description.replace(/[\n\r"]/g, "")}
                        </div>
                      </Option>
                    ))
                  : hsCodeData?.map((option) => (
                      <Option key={option.id} value={option.id}>
                        <div style={{ whiteSpace: "normal" }}>
                          {option.code} -{" "}
                          {option.description.replace(/[\n\r"]/g, "")}
                        </div>
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8} xs={24} sm={12} md={8} >
            <Form.Item
              labelCol={{ span: 24 }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              wrapperCol={{ span: 24 }}
              label="Product Name"
              name="productName"
              rules={[
                {
                  required: true,
                  message: "Product Name is required",
                },
              ]}
            >
              <Input
                maxLength={70}
                showCount
                placeholder="Example: Parboiled Rice"
                size={"large"}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Product Description"
              name="productdescription"
              rules={[
                {
                  required: true,
                  message: "Product Description is required",
                },
              ]}
            >
              <TextArea
                maxLength={255}
                showCount
                rows={5}
                placeholder={"Enter Product description "}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              label="Product Specification"
              name="productSpecification"
              rules={[
                {
                  required: true,
                  message: "Product Specification is required",
                },
              ]}
            >
              <TextArea
                maxLength={255}
                showCount
                rows={10}
                placeholder={
                  "Specify the Product Specifications to understand more details about the product."
                }
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <div className="sub-header"> Product Images</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {fileList.map((file, index) => {
                return (
                  <Upload
                    index={index}
                    src={file.src}
                    id={index}
                    label={
                      isValidString(file?.label) ? file?.label : getLabel(index)
                    }
                    originalName={file.originalName}
                    onUpload={onUpload}
                    onRemove={onRemove}
                    isFrom={"MyProduct"}
                  />
                );
              })}
            </div>
          </Col>
          <Col>
            <div className="upload-tips gx-mt-4">
              <div className="header" style={{ display: "flex" }}>
                <InfoCircleOutlined />
                &nbsp; Tips{" "}
              </div>
              <div className="desc">
                <Badge
                  status="default"
                  text="Supported file types: .jpg, .jpeg, .png"
                />
                <br />
                <Badge status="default" text="File must not more than 5MB" />
                <br />
                <Badge
                  status="default"
                  text="Make sure you have good resolution"
                />
                <br />
                <Badge
                  status="default"
                  text="Photo resolution must be more than 600x600 resolution"
                />
                <br />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="gx-mt-4">
          <Col span={24}>
            <Form.Item className="gx-text-center gx-mr-3" style={{ justifyContent: isMobile ? "center" : "end", display: "flex", flex: 1}}>
              <Button
                type="secondary"
                size="large"
                style={{ width: 150 }}
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ width: 150 }}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ProductDetails;
